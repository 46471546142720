import React from 'react';
import LegislationType from "../../LegislationType/LegislationType";
import {
    setLockedInLegislationTypeYou,
} from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const LockedInLegislationYou = ( props ) => {
    function onChange(val) {
        props.setLockedInLegislationTypeYou(val)
    }

    return (
        <LegislationType
            title={'Your'}
            value={props.lockedInLegislationYou}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        lockedInLegislationYou: state.savingsAndInvestments.you.lockedIn.legislationType
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLockedInLegislationTypeYou: val => dispatch(setLockedInLegislationTypeYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockedInLegislationYou);