import React from 'react';
import LegislationType from "../../LegislationType/LegislationType";
import {
    setLockedInLegislationTypeSpouse,
} from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const LockedInLegislationSpouse = ( props ) => {
    function onChange(val) {
        props.setLockedInLegislationTypeSpouse(val)
    }

    return (
        <LegislationType
            title={'Your'}
            value={props.lockedInLegislationSpouse}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        lockedInLegislationSpouse: state.savingsAndInvestments.spouse.lockedIn.legislationType
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLockedInLegislationTypeSpouse: val => dispatch(setLockedInLegislationTypeSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockedInLegislationSpouse);