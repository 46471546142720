import React, { useEffect, useState } from 'react';
import Error from "../../../Error/Error";

const LegislationType = ( props ) => {
    /*const legislationTypes = [
        'Federal',
        'AB',
        'BC',
        'MB',
        'NB',
        'NL',
        'NS',
        'NT',
        'NU',
        'ON',
        'PE',
        'QC',
        'SK',
        'YT',
    ];*/

    const legislationTypes = [
        'ON',
    ];

    const [value, setValue] = useState(props.value || '');
    const [error, setError] = useState(false);

    function onChange(e) {
        const val = e.target.value;
        setValue(val);
        validation(val);
        props.onChange(val)
    }

    function validation(val) {
        if(val === '') {
            setError(true)
        }else {
            setError(false)
        }
    }

    useEffect(()=> {
        validation(value)
    });

    return (
        <div className={'legislationTypeWrap'}>
            {
                error &&
                <Error
                    message='Legislation type is required'
                />
            }
            <div className="legislationType">
                <select
                    value={value}
                    name = "legislationType"
                    onChange={onChange}
                >
                    <option value = "">Select...</option>
                        {
                            legislationTypes.map((val, index)=> {
                                return <option key={index} value={val}>{val}</option>
                            })
                        }
                </select>
            </div>
            <p>{props.title} Legislation Type</p>
        </div>
    )
};

export default LegislationType;